@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./assests/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./assests/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('./assests/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./assests/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

*, body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif !important;
}

* {
  margin-block-start: 0;
  margin-block-end: 0;
}
